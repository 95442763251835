/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { DeviceType } from 'Type/Device.type';

import { getBlogPath } from '../../util/Blog';
import { MAX_CATEGORIES } from './BlogCategories.config';

import './BlogCategories.style';

/** @namespace Satisfly/MagefanBlog/Component/BlogCategories/Component */
export class BlogCategoriesComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        categories: PropTypes.array,
        isLoaded: PropTypes.bool,
        device: DeviceType.isRequired,
        closeOverlay: PropTypes.func.isRequired,
        isCategoryOrTagOverlayOpen: PropTypes.func.isRequired
    };

    static defaultProps = {
        categories: {},
        isLoaded: false
    };

    renderCategoryField(identifier, title, key, category_id, parent_category_id) {
        const { closeOverlay } = this.props;
        const blogPath = getBlogPath();
        return (
            <div
              mods={ parent_category_id > 1 ? 'BlogSubCategory' : 'BlogCategory' }
              block="BlogCategories"
              elem={ parent_category_id > 1 ? 'SubCategory' : 'Category' }
              key={ key }
            >
                <Link
                  to={ `${identifier === 'all-categories'
                      ? `/${blogPath}`
                      : `${blogPath}?category=${identifier}`}` }
                  title={ __('View %s', title) }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => (closeOverlay ? closeOverlay(title) : null) }
                >
                    <TextPlaceholder
                      mix={ { block: 'BlogCategories', elem: 'CategoryTitlePlaceholder' } }
                      content={ title }
                      length="medium"
                    />
                </Link>
                { this.renderSubCategory(category_id) }
            </div>
        );
    }

    renderSubCategory(category_id) {
        const { categories } = this.props;

        // eslint-disable-next-line max-len
        return Object.keys(categories).map((key) => (categories[key].parent_category_id === category_id && categories[key].parent_category_id > 1
            ? this.renderCategoryField(
                categories[key].identifier,
                categories[key].title,
                key,
                categories[key].category_id,
                categories[key].parent_category_id
            )
            : null));
    }

    renderCategory(category, key) {
        const {
            title, identifier, category_id, parent_category_id
        } = category;

        if (parent_category_id > 1) {
            return null;
        }

        return this.renderCategoryField(identifier, title, key, category_id);
    }

    renderContent() {
        const { categories, isLoaded, device } = this.props;
        const category = {};

        if (!isLoaded) {
            return Array(MAX_CATEGORIES)
                .fill()
                .map((_, i) => this.renderCategory(category, i));
        }

        if (isLoaded && categories.length === 0) {
            return __('No categories');
        }

        if (categories.length === MAX_CATEGORIES && device.isMobile) {
            categories.push({ category_id: 11, identifier: 'all-categories', title: 'All Categories' });
            return categories
                .sort((data, { category_id: id }) => data.category_id - id)
                .map((category, key) => this.renderCategory(category, key));
        }

        return categories.map((category, key) => this.renderCategory(category, key));
    }

    render() {
        const { device, isCategoryOrTagOverlayOpen } = this.props;
        const content = device.isMobile
            ? (
                <div
                  block="BlogCategories"
                  elem="Overlay"
                  mods={ { isOpen: isCategoryOrTagOverlayOpen } }
                >
                    { this.renderContent() }
                </div>
            )
            : this.renderContent();

        return (
            <div
              block="BlogCategories"
              elem="Wrapper"
              aria-hidden="true"
            >
                <h6 block="BlogCategories" elem="Title">
                    { __('Categories') }
                </h6>
                { content }
            </div>
        );
    }
}

export default BlogCategoriesComponent;
