/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesItem/Component */
export class CookiesItemComponent extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        groupId: PropTypes.string.isRequired,
        isEssential: PropTypes.bool.isRequired,
        description: PropTypes.string.isRequired,
        moreInfoOnClickHandler: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired
    };

    renderCheckbox() {
        const {
            onChange,
            groupId,
            isEssential,
            isSelected
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              attr={ {
                  id: `${ groupId }_checkbox`,
                  name: `${ groupId }_checkbox`,
                  checked: isSelected
              } }
              events={ {
                  onChange
              } }
              isDisabled={ isEssential }
              mods={ { type: 'Select' } }
            />

        );
    }

    renderMoreInfoBtn() {
        const {
            moreInfoOnClickHandler
        } = this.props;

        return (
            <button
              className="Button_isHollow"
              type="button"
              onClick={ moreInfoOnClickHandler }
            >
                { __('More Information') }
            </button>
        );
    }

    render() {
        const {
            name,
            description
        } = this.props;

        return (
             <div block="GroupData" elem="Wrapper">
                <div block="GroupData" elem="Header">
                    <h5>{ name }</h5>
                    { this.renderCheckbox() }
                </div>
                <p block="GroupData" elem="Header">{ description }</p>
                { this.renderMoreInfoBtn() }
             </div>
        );
    }
}

export default CookiesItemComponent;
