/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    ClickOutside as SourceClickOutside
} from 'SourceComponent/ClickOutside/ClickOutside.component';
import { isMobile } from 'Util/Mobile';

/** @namespace Satisfly/Component/ClickOutside/Component */
export class ClickOutside extends SourceClickOutside {
    componentDidMount() {
        if (isMobile.any()) {
            super.componentDidMount();
        } else {
            document.addEventListener('mousedown', this.handleClick);
        }
    }

    componentWillUnmount() {
        if (isMobile.any()) {
            super.componentWillUnmount();
        } else {
            document.removeEventListener('mousedown', this.handleClick);
        }
    }
}

export default ClickOutside;
