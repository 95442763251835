/* eslint-disable @scandipwa/scandipwa-guidelines/file-structure */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ChevronIcon from 'Component/ChevronIcon';
import {
    MyAccountTabListItem as SourceMyAccountTabListItem
} from 'SourceComponent/MyAccountTabListItem/MyAccountTabListItem.component';

import { MENU_ITEM_EXCLUDE } from './MyAccountTabListItem.config';

import './MyAccountTabListItem.override.style';

/** @namespace Satisfly/Component/MyAccountTabListItem/Component */
export class MyAccountTabListItem extends SourceMyAccountTabListItem {
    render() {
        const { children, tabEntry: [, { tabName, url }], isActive } = this.props;

        if (url === MENU_ITEM_EXCLUDE) {
            return null;
        }

        return (
            <li
              block="MyAccountTabListItem"
              mods={ { isActive } }
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ this.changeActiveTab }
                  role="link"
                >
                    { tabName }
                    <ChevronIcon />
                </button>
                { children }
            </li>
        );
    }
}

export default MyAccountTabListItem;
