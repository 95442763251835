/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { ImageContainer as SourceContainer } from 'SourceComponent/Image/Image.container';

/** @namespace Satisfly/Component/Image/Container */
export class ImageContainer extends SourceContainer {
    static propTypes = {
        ...super.propTypes,
        preventLazy: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        preventLazy: false
    };

    containerProps() {
        const { preventLazy } = this.props;

        return {
            ...super.containerProps(),
            preventLazy
        };
    }
}

export default ImageContainer;
