/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import domToReact from 'html-react-parser/lib/dom-to-react';

import Accordion from 'Component/Accordion';
import {
    Html as SourceHtml,
    WidgetFactory
} from 'SourceComponent/Html/Html.component';

export {
    WidgetFactory
};

/** @namespace Satisfly/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { attribs: ['data-accordion'] },
            replace: this.prepareAccordion
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    prepareAccordion({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        return (
            <Accordion { ...attributes }>{ domToReact(children, this.parserOptions) }</Accordion>
        );
    }
}

export default Html;
