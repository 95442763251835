/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import { PostsSearchDispatcher } from '../../store/PostsSearch';
import BlogSearchOverlay from './BlogSearchOverlay.component';
import { SEARCH_TIMEOUT } from './BlogSearchOverlay.config';

/** @namespace Satisfly/MagefanBlog/Component/BlogSearchOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    searchResults: state.PostsSearchReducer.postsInSearch,
    isLoading: state.PostsSearchReducer.isLoading
});

/** @namespace Satisfly/MagefanBlog/Component/BlogSearchOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    makeSearchRequest: (options) => PostsSearchDispatcher.handleData(dispatch, options),
    clearSearchResults: () => PostsSearchDispatcher.clearSearchResults(dispatch)
});

/** @namespace Satisfly/MagefanBlog/Component/BlogSearchOverlay/Container */
export class BlogSearchOverlayContainer extends PureComponent {
    static propTypes = {
        makeSearchRequest: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        searchResults: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired
    };

    containerFunctions = {
        makeSearchRequest: this.makeSearchRequest.bind(this)
    };

    componentDidUpdate(prevProps) {
        const { searchCriteria: prevSearchCriteria } = prevProps;
        const { searchCriteria, clearSearchResults } = this.props;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        if (searchCriteria !== prevSearchCriteria) {
            this.timeout = setTimeout(() => {
                this.timeout = null;
                clearSearchResults();
                this.makeSearchRequest();
            }, SEARCH_TIMEOUT);
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isSearchVisible: false
        };
    }

    onSearchOutsideClick() {
        this.setState({ isSearchVisible: false });
    }

    makeSearchRequest() {
        const { makeSearchRequest, clearSearchResults, searchCriteria } = this.props;

        this.setState({ isSearchVisible: true });

        if (searchCriteria) {
            clearSearchResults();
            makeSearchRequest({
                filter: { search: { eq: encodeURIComponent(searchCriteria) } },
                getMedia: true,
                getBasicDetails: true
            });
        }
    }

    containerProps = () => {
        const { isSearchVisible } = this.state;

        const {
            searchCriteria, clearSearchResults, searchResults, isLoading, onSearchOutsideClick
        } = this.props;

        return {
            searchCriteria,
            searchResults,
            isLoading,
            clearSearchResults,
            onSearchOutsideClick,
            isSearchVisible
        };
    };

    render() {
        return <BlogSearchOverlay { ...this.containerProps() } { ...this.containerFunctions } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogSearchOverlayContainer);
