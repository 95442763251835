/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    getFilterDetails, getFilterDifferences, getFiltersCategoryName, getFiltersCount
} from '../data/filter';
import {
    GTM_EVENT_KEY_ALL_FILTERS_REMOVED,
    GTM_EVENT_KEY_FILTER_APPLIED,
    GTM_EVENT_KEY_FILTER_REMOVED
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/Filter/fireProductFilterAppliedEvent */
export const fireFilterAppliedEvent = debounceCallback(async (addedFilters, filtersDetails, customerId) => {
    const filterKey = Object.keys(addedFilters[0])[0];
    const filterValue = addedFilters[0][filterKey][0];

    pushToDataLayer({
        event: GTM_EVENT_KEY_FILTER_APPLIED,
        customerId,
        ...getFilterDetails(filtersDetails, filterKey, filterValue)
    });
});

/** @namespace Scandiweb/Gtm/Event/Filter/fireProductFilterRemovedEvent */
export const fireFilterRemovedEvent = debounceCallback(async (removedFilters, filtersDetails, customerId) => {
    const filterKey = Object.keys(removedFilters[0])[0];
    const filterValue = removedFilters[0][filterKey][0];

    pushToDataLayer({
        event: GTM_EVENT_KEY_FILTER_REMOVED,
        customerId,
        ...getFilterDetails(filtersDetails, filterKey, filterValue)
    });
});

export const fireAllFiltersRemovedEvent = debounceCallback(async (removedFilters, filtersDetails, customerId) => {
    const filterKeys = removedFilters.map((filter) => Object.keys(filter)[0]);
    // ^^^ in each element of array there is an object with only one property so [0] is for that

    pushToDataLayer({
        event: GTM_EVENT_KEY_ALL_FILTERS_REMOVED,
        customerId,
        filterCategories: getFiltersCategoryName(filtersDetails, filterKeys).join(',')
    });
});

/** @namespace Satisfly/Gtm/Event/Filter/handleProductFilterEvents */
export const handleProductFilterEvents = (oldCustomFilters, customFilters, filtersDetails, customerId) => {
    const lastPLPUrl = sessionStorage.getItem('lastPLPUrl');
    const [removedFilters, addedFilters] = getFilterDifferences(oldCustomFilters, customFilters);
    const currentLocation = location.origin + location.pathname;
    const addedFiltersCount = getFiltersCount(addedFilters);
    const removedFiltersCount = getFiltersCount(removedFilters);
    const pushRemovedFiltersToDataLayer = lastPLPUrl && lastPLPUrl === currentLocation;
    // ^^^ if it's undefined means it's first time here and we don't need to dispatch RemovedFilters
    // ^^^ if they're equal it means we might removed/added filter(s) if they're not we don't need to dispatch removedFilters
    // ^^^ if we don't have any item in it, don't push it

    if (pushRemovedFiltersToDataLayer) {
        // vvv If it's < 1 it means nothing has been added. If it's 1 means we removed filter by clicking on filters
        if (removedFiltersCount === 1) {
            fireFilterRemovedEvent(removedFilters, filtersDetails, customerId);
        }

        // vvv If it's < 1 it means nothing has been added. If it's >1 means we removed filters by clicking on 'reset all' button
        if (removedFiltersCount > 1) {
            fireAllFiltersRemovedEvent(removedFilters, filtersDetails, customerId);
        }
    }

    // vvv If it's < 1 it means nothing has been added. If it's >1 means we added filter through URL not by clicking on filters
    if (addedFiltersCount === 1) {
        fireFilterAppliedEvent(addedFilters, filtersDetails, customerId);
    }

    sessionStorage.setItem('lastPLPUrl', currentLocation);
};
