/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_TAGS } from './Tags.action';

export const emptyTags = {
    items: [],
    count: 0
};

/** @namespace Satisfly/MagefanBlog/Store/Tags/Reducer/getInitialState */
export const getInitialState = () => ({
    tags: emptyTags
});

/** @namespace Satisfly/MagefanBlog/Store/Tags/Reducer/TagsReducer */
export const TagsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_TAGS:
        const {
            tags: { items, total_count: count },
            isLoaded
        } = action;

        return {
            ...state,
            tags: {
                items,
                count
            },
            isLoaded
        };
    default:
        return state;
    }
};

export default TagsReducer;
