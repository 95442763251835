/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    CloseIcon as SourceCloseIcon
} from 'SourceComponent/CloseIcon/CloseIcon.component';

/** @namespace Satisfly/Component/CloseIcon/Component */
export class CloseIcon extends SourceCloseIcon {
    render() {
        return (
            <svg
              block="CloseIcon"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M15 5L5 15" stroke="#1E1E1E" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 5L15 15" stroke="#1E1E1E" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default CloseIcon;
