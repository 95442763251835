/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';
import { getProductData } from './product';

/** @namespace Satisfly/Gtm/Data/Cart/getCartItemData */
export const getCartItemData = async (item) => {
    const {
        quantity,
        prices: {
            row_total_including_tax: {
                value: price
            } = {}
        } = {},
        product
    } = item;

    return {
        ...await getProductData(product),
        price,
        quantity
    };
};

/** @namespace Satisfly/Gtm/Data/Cart/getCartItemsData */
export const getCartItemsData = async () => {
    // vvv Wait for cart id
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.id);

    const {
        cartTotals: {
            items = []
        }
    } = getStore().getState().CartReducer;

    if (items.length === 0) {
        return {};
    }

    return Promise.all(items.map(getCartItemData));
};

/** @namespace Satisfly/Gtm/Data/Cart/getRemoveFromCartData */
export const getRemoveFromCartData = async (item, currencyCode) => ({
    ecommerce: {
        currencyCode,
        // ^^^ We can not read currencyCode via getStore (Redux limitation) => forced to pass
        remove: {
            products: [await getCartItemData(item)]
        }
    }
});

/** @namespace Satisfly/Gtm/Data/Cart/getAddToCartData */
export const getAddToCartData = async (item, currencyCode) => ({
    ecommerce: {
        currencyCode,
        // ^^^ We can not read currencyCode via getStore (Redux limitation) => forced to pass
        add: {
            products: [await getCartItemData(item)]
        }
    }
});
