/* eslint-disable @scandipwa/scandipwa-guidelines/file-structure */
/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import PostsDetails from '../route/PostsDetails';
import { getBlogPath } from '../util/Blog';

export const PostsListing = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/PostsListing')
);

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export class RouterPlugin {
    /**
     * Adds blog url handles to the router SWITCH_ITEMS_TYPE list
     * @param originalMember
     * @returns {*[]}
     */
    /* eslint-disable */
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={withStoreRegex(`/${getBlogPath()}/:handle`)} component={PostsDetails} />,
            position: 735
        },
        {
            component: <Route path={withStoreRegex(`/${getBlogPath()}`)} component={PostsListing} />,
            position: 921
        }
    ];
    /* eslint-enable */
}

const { switchItems } = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};

export default config;
