/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Link from 'Component/Link';

import { CheckboxItemDataType } from '../../type/gdpr.type';

import './Checkbox.style';

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Component */
export class CheckboxComponent extends PureComponent {
    static propTypes = {
        data: CheckboxItemDataType,
        handleCheckboxChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        data: {}
    };

    renderLink(name, consent_text) {
        return (
            <p block={ name }>
                { consent_text }
            </p>
        );
    }

    render() {
        const {
            data: {
                name,
                consentText,
                isEnabled,
                isRequired,
                link
            } = {},
            handleCheckboxChange
        } = this.props;

        if (!isEnabled) {
            return null;
        }

        return (
            <span className="gdpr-Checkbox">
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ this.renderLink(name, consentText) }
              attr={ {
                  name
              } }
              events={ {
                  onChange: handleCheckboxChange
              } }
              validationRule={ {
                  isRequired
              } }
            />
            <Link
              isOpenInNewTab
              className="gdpr-checkbox-link"
              to={ `/${link}` }
            >
                { name }
            </Link>

            </span>
        );
    }
}

export default CheckboxComponent;
