/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/** @namespace Satisfly/MagefanBlog/Query/BlogTags/Query */
export class BlogTagsQuery {
    getQuery() {
        const items = BlogTagsQuery.itemField();
        const page = BlogTagsQuery.pageField();

        return new Field('blogTags').addField(items).addFieldList(page).setAlias('tags');
    }

    static itemField() {
        const fields = [];

        fields.push('tag_id', 'identifier', 'title');

        return new Field('items').addFieldList(fields);
    }

    /**
      * Prepare the pages field
      */
    static pageField() {
        return ['total_count'];
    }
}

export default new BlogTagsQuery();
