/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import Checkboxes from '../component/Checkboxes';
import {
    CHECKOUT_SCOPE,
    CONTACT_US_SCOPE,
    NEWSLETTER_SUBSCRIPTION_SCOPE,
    REGISTRATION_SCOPE
} from '../config/GDPR.config';
import {
    sendTheConsentLog
} from '../util/GDPR.util';

const addCheckboxToNewsletterSubscriptionRenderAction = (args, callback) => (
    <>
       <Checkboxes scope={ NEWSLETTER_SUBSCRIPTION_SCOPE } />
        { callback(...args) }
    </>
);

const addToNewsLetterOnFormSubmit = (args, callback) => {
    callback(...args);

    const [fields] = args;

    // eslint-disable-next-line dot-notation
    sendTheConsentLog(NEWSLETTER_SUBSCRIPTION_SCOPE, fields.elements['newsletterEmail'].value);
};

const addToOnFormSubmit = (scope) => (args, callback) => {
    callback(...args);
    sendTheConsentLog(scope);
};

const addToRenderSubmitButton = (scope) => (args, callback) => (
    <>
        <Checkboxes scope={ scope } />
        { callback(...args) }
    </>
);

export default {
    'Component/NewsletterSubscription/Component': {
        'member-function': {
            renderActions: addCheckboxToNewsletterSubscriptionRenderAction
        }
    },
    'Component/NewsletterSubscription/Container': {
        'member-function': {
            onFormSubmit: addToNewsLetterOnFormSubmit
        }
    },
    'Component/MyAccountNewsletterSubscription/Component': {
        'member-function': {
            renderActions: addToRenderSubmitButton(NEWSLETTER_SUBSCRIPTION_SCOPE)
        }
    },
    'Component/MyAccountNewsletterSubscription/Container': {
        'member-function': {
            onCustomerSave: addToOnFormSubmit(NEWSLETTER_SUBSCRIPTION_SCOPE)
        }
    },
    'Component/MyAccountCreateAccount/Component': {
        'member-function': {
            renderSubmitButton: addToRenderSubmitButton(REGISTRATION_SCOPE)
        }
    },
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            onSuccess: addToOnFormSubmit(REGISTRATION_SCOPE)
        }
    },
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderActions: addToRenderSubmitButton(CHECKOUT_SCOPE)
        }
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: addToOnFormSubmit(CHECKOUT_SCOPE)
        }
    },
    'Component/MyAccountCustomerForm/Component': {
        'member-function': {
            renderActions: addToRenderSubmitButton(CONTACT_US_SCOPE),
            onFormSuccess: addToOnFormSubmit(CONTACT_US_SCOPE)
        }
    }
};
