/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import BlogRelatedPosts from './BlogRelatedPosts.component';

/** @namespace Satisfly/MagefanBlog/Component/BlogRelatedPosts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    related_posts: state.PostsDetailsReducer.post.related_posts,
    isLoaded: state.PostsDetailsReducer.post.post_id
});

/** @namespace Satisfly/MagefanBlog/Component/BlogRelatedPosts/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BlogRelatedPosts);
