/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/** @namespace Satisfly/Gtm/Data/Sort/getSortDetailsData */
export const getSortDetailsData = async (sort) => {
    const { sortDirection, sortKey } = sort;
    return {
        sortDirection: sortDirection.toUpperCase(),
        sortKey: capitalizeFirstLetter(sortKey)
    };
};
