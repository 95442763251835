/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    MyAccountOrderTotals as SourceMyAccountOrderTotals
} from 'SourceComponent/MyAccountOrderTotals/MyAccountOrderTotals.component';
import { formatPrice } from 'Util/Price';

import './MyAccountOrderTotals.override.style';

/** @namespace Satisfly/Component/MyAccountOrderTotals/Component */
export class MyAccountOrderTotals extends SourceMyAccountOrderTotals {
    renderPriceLine(title, price, currency, mix = {}, key) {
        const {
            total: { grand_total: { currency: defaultCurrency } },
            colSpanLabelCount,
            colSpanPriceCount
        } = this.props;

        return (
            <tr mix={ mix } key={ key }>
                <th colSpan={ colSpanLabelCount }><span>{ title }</span></th>
                <td colSpan={ colSpanPriceCount }>{ formatPrice(price, currency || defaultCurrency) }</td>
            </tr>
        );
    }

    renderContent() {
        const {
            total: {
                subtotal: {
                    value: subtotalPrice
                },
                shipping_handling: {
                    total_amount: {
                        value: shippingHandlingPrice
                    }
                }
            }
        } = this.props;

        return (
            <>
                { this.renderPriceLine(__('Subtotal'), subtotalPrice) }
                { this.renderDiscounts() }
                { this.renderPriceLine(__('Shipping & Handling'), shippingHandlingPrice) }
                { this.renderTaxes() }
                { this.renderBaseGrandTotal() }
            </>
        );
    }
}

export default MyAccountOrderTotals;
