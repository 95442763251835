/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    IMAGE_LOADED, IMAGE_LOADING,
    IMAGE_NOT_FOUND,
    IMAGE_NOT_SPECIFIED
} from 'Component/Image/Image.config';

const MAGENTO_IMAGE_OMEN = '{{media';

export class ImagePlugin {
    renderImage = (args, callback, instance) => {
        const { src } = instance.props;

        const magentoImage = this.verifyImage(src);

        if (!magentoImage) {
            return (
                <>
                    { callback.apply(instance, args) }
                </>
            );
        }

        return this.renderMagentoImage(instance, magentoImage);
    };

    renderMagentoImage(instance, src) {
        const {
            alt,
            isPlaceholder,
            style,
            title
        } = instance.props;
        const { imageStatus } = instance.state;

        if (isPlaceholder) {
            return null;
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return instance.renderImageNotFound();
        case IMAGE_NOT_SPECIFIED:
            return (
                    <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:
            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ `/media/${src}` || '' }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  title={ title }
                  onLoad={ instance.onLoad }
                  onError={ instance.onError }
                />
            );
        default:
            return null;
        }
    }

    verifyImage(src = '') {
        if (src && src.includes(MAGENTO_IMAGE_OMEN)) {
            return src.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, '');
        }

        return false;
    }
}

const { renderImage } = new ImagePlugin();

export default {
    'Component/Image/Component': {
        'member-function': {
            renderImage
        }
    }
};
