/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';
import { DeviceType } from 'Type/Device.type';

import BlogRelatedPosts from '../../component/BlogRelatedPosts';
import BlogSidebar from '../../component/BlogSidebar';
import { convertDateFormat } from '../../util/Date';

import './PostsDetails.style';

/**
   * @class PostsDetails
   * @namespace Satisfly/MagefanBlog/Route/PostsDetails/Component */
export class PostsDetailsComponent extends PureComponent {
    static propTypes = {
        closeOverlay: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        post: PropTypes.object.isRequired,
        postIsLoaded: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                handle: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        isCategoryOrTagOverlayOpen: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
        isPostMatch: PropTypes.bool.isRequired,
        isLoaded: PropTypes.bool.isRequired
    };

    renderTitle() {
        const {
            post: { title }
        } = this.props;

        return (
            <h1 mix={ { block: 'PostsDetails', elem: 'Title' } }>
                <TextPlaceholder length="medium" content={ title } />
            </h1>
        );
    }

    /**
     * Renders the featured image
     * @returns {*}
     */
    renderImage() {
        const {
            post: { post_id, first_image }
        } = this.props;

        if (!first_image && post_id) {
            return null;
        }

        return (
            <Image
              mix={ {
                  block: 'PostsDetails',
                  elem: 'FeaturedImage'
              } }
              src={ first_image }
              ratio="custom"
              isPlaceholder={ !first_image }
            />
        );
    }

    renderPublishDate() {
        const {
            post: { publish_time }
        } = this.props;

        if (!publish_time) {
            return null;
        }

        return (
            <div mix={ { block: 'PostsDetails', elem: 'DateWrapper' } }>
                <TextPlaceholder
                  mix={ { block: 'BlogRecentPosts', elem: 'DatePlaceholder' } }
                  content={ convertDateFormat(publish_time) }
                />
            </div>
        );
    }

    renderCmsContent() {
        const {
            post: { content }
        } = this.props;

        if (!content) {
            return <TextPlaceholder mix={ { block: 'PostsDetails', elem: 'ContentPlaceholder' } } length="custom" />;
        }

        return (
            <div mix={ { block: 'PostsDetails', elem: 'Content' } }>
                <Html content={ content } />
            </div>
        );
    }

    renderContent() {
        return (
            <div block="PostsDetails" elem="ContentWrapper">
                { this.renderCmsContent() }
            </div>
        );
    }

    renderRelatedPosts() {
        const {
            post: { related_posts = [] }
        } = this.props;

        if (!related_posts || related_posts.length === 0) {
            return null;
        }

        return <BlogRelatedPosts />;
    }

    renderMainContent() {
        return (
            <div>
                { this.renderTitle() }
                { this.renderPublishDate() }
                { this.renderImage() }
                { this.renderContent() }
                { this.renderRelatedPosts() }
            </div>
        );
    }

    renderPostListingDetails() {
        const { postIsLoaded } = this.props;

        if (postIsLoaded) {
            return this.renderMainContent();
        }

        return null;
    }

    render() {
        const {
            isPostMatch, isLoaded, device, isCategoryOrTagOverlayOpen, closeOverlay
        } = this.props;

        if (!isPostMatch && isLoaded) {
            return <NoMatch />;
        }

        if (isPostMatch) {
            return (
                <main block="PostsDetails">
                    <ContentWrapper wrapperMix={ { block: 'PostsDetails', elem: 'Wrapper' } } label="Post">
                        <div
                          block="PostsDetails"
                          elem="ColumnWrapper"
                          mix={ { block: 'PostsListing', elem: 'ColumnWrapper' } }
                        >
                            <div block="PostsDetails" elem="Description">
                                { this.renderPostListingDetails() }
                            </div>
                            <BlogSidebar
                              device={ device }
                              isCategoryOrTagOverlayOpen={ isCategoryOrTagOverlayOpen }
                              closeOverlay={ closeOverlay }
                            />
                        </div>
                    </ContentWrapper>
                </main>
            );
        }

        return null;
    }
}

export default PostsDetailsComponent;
