/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { showNotification } from 'SourceStore/Notification/Notification.action';
import { QueryDispatcher } from 'SourceUtil/Request';

import BlogPostsQuery from '../../query/BlogPosts.query';
import { postsException, updatePosts } from './Posts.action';

/**
 * Posts Dispatcher
 * @extends QueryDispatcher
 * @namespace Satisfly/MagefanBlog/Store/Posts/Dispatcher */
export class PostsDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ posts }, dispatch) {
        if (posts) {
            dispatch(updatePosts(posts));
        }
    }

    onError([{ message }], dispatch) {
        if (message) {
            dispatch(postsException(message));
            dispatch(showNotification('error', __('Error fetching Posts!'), message));
        }
    }

    prepareRequest(options) {
        return BlogPostsQuery.getQuery(options);
    }
}

export default new PostsDispatcher();
