/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountContainer as SourceMyAccountContainer
} from 'SourceRoute/MyAccount/MyAccount.container';
import OrderReducer from 'Store/Order/Order.reducer';
import {
    ACCOUNT_INFORMATION,
    ADDRESS_BOOK,
    FIRST_SECTION,
    MY_ACCOUNT, MY_ORDERS,
    MY_WISHLIST, NEWSLETTER_SUBSCRIPTION,
    SECOND_SECTION, THIRD_SECTION
} from 'Type/Account.type';
import { withReducers } from 'Util/DynamicReducer';

export {
    mapStateToProps,
    mapDispatchToProps
};

SourceMyAccountContainer.tabMap = {
    [MY_ACCOUNT]: {
        url: '',
        tabName: __('My Account'),
        section: FIRST_SECTION
    },
    [MY_ORDERS]: {
        url: '/sales/order/history',
        tabName: __('Orders'),
        section: FIRST_SECTION,
        isFullUrl: true
    },
    [MY_WISHLIST]: {
        url: '/wishlist',
        tabName: __('My Wish List'),
        section: FIRST_SECTION,
        isFullUrl: true
    },
    [ADDRESS_BOOK]: {
        url: '/customer/address',
        tabName: __('Address Book'),
        section: SECOND_SECTION,
        isFullUrl: true
    },
    [ACCOUNT_INFORMATION]: {
        url: '/edit',
        tabName: __('Account Information'),
        title: __('Edit Account Information'),
        section: SECOND_SECTION
    },
    [NEWSLETTER_SUBSCRIPTION]: {
        url: '/newsletter/manage',
        tabName: __('Newsletter'),
        section: THIRD_SECTION,
        isFullUrl: true
    }
};

export default withRouter(withReducers({
    OrderReducer
})(connect(mapStateToProps, mapDispatchToProps)(SourceMyAccountContainer)));
