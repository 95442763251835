/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';

import BlogTags from '../../query/BlogTags.query';
import { updateTags } from './Tags.action';

/** @namespace Satisfly/MagefanBlog/Store/Tags/Dispatcher */
export class TagsDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ tags }, dispatch) {
        if (tags) {
            dispatch(updateTags(tags));
        }
    }

    onError({ message }, dispatch) {
        if (message) {
            dispatch(showNotification('error', __('Error fetching Tags!'), message));
        }
    }

    prepareRequest(options) {
        return BlogTags.getQuery(options);
    }
}

export default new TagsDispatcher();
