/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace Satisfly/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (props) => {
    const { address } = props;
    return [
        {
            key: 'firstname',
            label: __('First name'),
            source: address
        },
        {
            key: 'lastname',
            label: __('Last name'),
            source: address
        },
        {
            key: 'street',
            label: __('Street and number'),
            source: address
        },
        {
            key: 'postcode',
            label: __('Zip / Postal code'),
            source: address
        },
        {
            key: 'city',
            label: __('City'),
            source: address
        },
        {
            key: 'telephone',
            label: __('Phone no'),
            source: address
        },
        {
            key: 'company',
            label: __('Company name'),
            source: address
        },
        {
            key: 'vat_id',
            label: __('VAT Number'),
            source: address
        }
    ];
};

export default getAddressTablePairArray;
