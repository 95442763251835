/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { UPDATE_TOTALS, updateTotals } from 'SourceStore/Cart/Cart.action';

import { getCustomerData } from '../../data/customer';
import { fireAddToCartEvent, fireRemoveFromCartEvent } from '../../event/cart';

// vvv Index items, so we have SKU => item
const indexItems = (items = []) => items.reduce(
    (acc, item) => ({ ...acc, [item.sku]: item }),
    {}
);

const fireCartEvents = (args, callback) => {
    const [state, action] = args;
    const { type, customerId } = action;
    const newState = callback(...args);

    if (type !== UPDATE_TOTALS) {
        return newState;
    }

    const {
        cartTotals: {
            items, id, prices: {
                grand_total: {
                    currency: currencyCode
                } = {}
            } = {}
        }
    } = newState;
    const { cartTotals: { items: prevItems, id: prevId } } = state;
    const indexedItems = indexItems(items);
    const indexedPrevitems = indexItems(prevItems);

    if (!id || !prevId) {
        return newState;
    }

    // TODO: compare items, qty
    Object.entries(indexedItems).forEach(([sku, item]) => {
        const prevItem = indexedPrevitems[sku];

        if (!prevItem) {
            fireAddToCartEvent(item, currencyCode, customerId);
            // ^^^ item was added
            return;
        }

        const { quantity } = item;
        const { quantity: prevQuantity } = prevItem;

        // eslint-disable-next-line fp/no-delete
        delete indexedPrevitems[sku];
        // ^^^ Remove processed indexed items, all which will remain
        // in the map should be considered removed items

        if (quantity === prevQuantity) {
            return;
        }

        if (quantity > prevQuantity) {
            // ^^^ Item qty increased

            fireAddToCartEvent(
                {
                    ...item,
                    quantity: quantity - prevQuantity
                    // ^^^ If qty was increased => treat as delta add to cart
                },
                currencyCode,
                customerId
            );

            return;
        }

        // vvv Item qty decreased
        fireRemoveFromCartEvent(
            {
                ...item,
                quantity: prevQuantity - quantity
                // ^^^ if qty was decreased => treat as delta remove from cart
            },
            currencyCode,
            customerId
        );
    });

    Object.values(indexedPrevitems).forEach((item) => {
        // ^^^ item was removed
        fireRemoveFromCartEvent(item, currencyCode, customerId);
    });

    return callback(...args);
};

const aroundUpdateCartData = async (args) => {
    const [cartData, dispatch] = args;
    const { customerId } = await getCustomerData();

    dispatch(
        {
            ...updateTotals(cartData),
            customerId
        }
    );
};

export default {
    'Store/Cart/Reducer/CartReducer': {
        function: fireCartEvents
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData: aroundUpdateCartData
        }
    }
};
