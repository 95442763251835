/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import GDPRFooterLink from '../component/GDPRFooterLink';

const renderGDPR = (args, callback) => (
    <>
            { callback(...args) }
            <GDPRFooterLink />
    </>
);

export default {
    'Satisfly/Component/Footer/Component': {
        'member-function': {
            renderGDPR
        }
    }
};
