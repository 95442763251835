/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import BlogPostCard from '../BlogPostCard';
import { NUMBER_OF_RELATED_POSTS } from './BlogRelatedPosts.config';

import './BlogRelatedPosts.style';

/** @namespace Satisfly/MagefanBlog/Component/BlogRelatedPosts/Component */
export class BlogRelatedPostsComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        related_posts: PropTypes.array,
        isLoaded: PropTypes.number
    };

    static defaultProps = {
        related_posts: [],
        isLoaded: null
    };

    renderRelatedPosts() {
        const { related_posts, isLoaded } = this.props;

        if (!related_posts.length && !isLoaded) {
            return Array.from({ length: NUMBER_OF_RELATED_POSTS }, (_, i) => <BlogPostCard key={ i } isPlaceholder />);
        }

        return related_posts.map((post) => (
            <BlogPostCard
              key={ post.post_id }
              post={ post }
              mix={ { block: 'BlogRelatedPosts', elem: 'PostCard' } }
              isRelated
            />
        ));
    }

    renderTitle() {
        const { related_posts } = this.props;

        if (!related_posts.length) {
            return null;
        }

        return (
            <div block="BlogRelatedPosts" elem="TitleWrapper">
                <h3 block="BlogRelatedPosts" elem="Title">
                    { __('Related Posts') }
                </h3>
            </div>
        );
    }

    render() {
        return (
            <div block="BlogRelatedPosts" elem="Wrapper">
                { this.renderTitle() }
                <div block="BlogRelatedPosts" elem="PostsWrapper">
                    { this.renderRelatedPosts() }
                </div>
            </div>
        );
    }
}

export default BlogRelatedPostsComponent;
