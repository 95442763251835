/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const GTM_EVENT_KEY_GENERAL = 'general';
// vvv Event/plugin "notFound"
export const GTM_EVENT_KEY_NOT_FOUND = 'notFound';
// vvv Event/plugin "user"
export const GTM_EVENT_KEY_USER_LOGIN = 'userLogin';
export const GTM_EVENT_KEY_USER_LOGIN_ATTEMPT = 'userLoginAttempt';
export const GTM_EVENT_KEY_USER_REGISTER = 'userRegister';
export const GTM_EVENT_KEY_USER_REGISTER_ATTEMPT = 'userRegisterAttempt';
export const GTM_EVENT_KEY_USER_LOGOUT = 'userLogout';
// vvv Event/plugin "search"
export const GTM_EVENT_KEY_SEARCH = 'siteSearch';
export const GTM_EVENT_KEY_SEARCH_STARTED = 'siteSearchStarted';
// vvv Event/plugin "list"
export const GTM_EVENT_KEY_IMPRESSIONS = 'impression';
export const GTM_EVENT_KEY_PRODUCT_CLICK = 'productClick';
// vvv Event/plugin "product"
export const GTM_EVENT_KEY_PRODUCT_DETAIL = 'productDetail';
// vvv Event/plugin "cart"
export const GTM_EVENT_KEY_ADD_TO_CART = 'addToCart';
export const GTM_EVENT_KEY_REMOVE_FROM_CART = 'removeFromCart';
// vvv Event/plugin "checkout"
export const GTM_EVENT_KEY_CHECKOUT = 'checkout';
export const GTM_EVENT_KEY_CHECKOUT_OPTION = 'checkoutOption';
// vvv Event/plugin "purchase"
export const GTM_EVENT_KEY_PURCHASE = 'purchase';
// vvv Event/plugin "sort"
export const GTM_EVENT_KEY_SORT = 'sort';
// vvv Event/plugin "wishlist"
export const GTM_EVENT_KEY_ADD_TO_WISHLIST = 'addToWishlist';
export const GTM_EVENT_KEY_REMOVE_FROM_WISHLIST = 'removeFromWishlist';
export const GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST = 'removeAllItemsFromWishlist';
// vvv Event/plugin "errorMessage"
export const GTM_EVENT_KEY_ERROR_MESSAGE = 'errorMessage';
// vvv Event/plugin "pageReload"
export const GTM_EVENT_KEY_PAGE_RELOAD = 'pageReload';
// vvv Event/plugin "filter"
export const GTM_EVENT_KEY_FILTER_APPLIED = 'filterApplied';
export const GTM_EVENT_KEY_FILTER_REMOVED = 'filterRemoved';
export const GTM_EVENT_KEY_ALL_FILTERS_REMOVED = 'allFiltersRemoved';
export const ECOMMERCE = 'ecommerce';
