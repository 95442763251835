/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getCustomerData } from '../data/customer';
import { getProductDetailsData } from '../data/product';
import { GTM_EVENT_KEY_PRODUCT_DETAIL } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/General/fireNotFoundEvent */
export const fireProductDetailsEvent = debounceCallback(async (product) => {
    const { customerId } = await getCustomerData();

    const { sku } = product;
    if (!sku) {
        return;
    }

    pushToDataLayer({
        event: GTM_EVENT_KEY_PRODUCT_DETAIL,
        customerId,
        ...await getProductDetailsData(product)
    });
});
