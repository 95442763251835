/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import { GDPRContext } from '../../context/GDPR.context';
import { cookiesGroupDataType } from '../../type/gdpr.type';
import CookiesItemComponent from './CookiesItem.component';

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesItem/CookiesItems/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesItem/CookiesItems/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesItem/CookiesItems/Container */
export class CookiesItemsContainer extends DataContainer {
    static propTypes = {
        groupData: cookiesGroupDataType.isRequired
    };

    static contextType = GDPRContext;

    __construct(props) {
        super.__construct(props);

        const {
            groupData: {
                checked
            }
        } = this.props;

        this.state = {
            isSelected: checked
        };
    }

    containerProps() {
        const {
            groupData: {
                name,
                groupId,
                checked,
                isEssential,
                description
            }
        } = this.props;
        const { isSelected } = this.state;

        return {
            name,
            groupId,
            isChecked: checked,
            isEssential,
            description,
            isSelected
        };
    }

    containerFunctions = {
        onChange: this.onChange.bind(this),
        moreInfoOnClickHandler: this.moreInfoOnClickHandler.bind(this)
    };

    onChange() {
        const { handleOnChangeChecked } = this.context;
        const { groupData: { name } } = this.props;
        const { isSelected } = this.state;

        this.setState({ isSelected: !isSelected });
        handleOnChangeChecked(name);
    }

    moreInfoOnClickHandler() {
        const { groupData } = this.props;
        const { showCookiesDetailsModal } = this.context;

        showCookiesDetailsModal(groupData);
    }

    render() {
        return (
            <CookiesItemComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiesItemsContainer);
