/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import SearchIcon from 'Component/SearchIcon';
import { MixType } from 'Type/Common.type';

import BlogSearchOverlay from '../BlogSearchOverlay';

import './BlogSearchBar.style';

/** @namespace Satisfly/MagefanBlog/Component/BlogSearchBar/Component */
export class BlogSearchBarComponent extends PureComponent {
    static propTypes = {
        searchCriteria: PropTypes.string.isRequired,
        isSearchVisible: PropTypes.bool.isRequired,
        onSearchBarClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onCloseButtonClick: PropTypes.func.isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    };

    renderCloseButton() {
        const { isSearchVisible, onCloseButtonClick } = this.props;

        if (!isSearchVisible) {
            return;
        }

        // eslint-disable-next-line consistent-return
        return (
            <button
              key="close"
              block="BlogSearchBar"
              elem="Button"
              mods={ { type: 'close' } }
              onClick={ onCloseButtonClick }
              aria-label={ __('Close') }
            >
                <CloseIcon />
            </button>
        );
    }

    renderSearchButton() {
        const { isSearchVisible } = this.props;
        if (isSearchVisible) {
            return;
        }

        // eslint-disable-next-line consistent-return
        return (
            <button
              key="blogsearch"
              block="BlogSearchBar"
              elem="Button"
              mods={ { type: 'blogsearch' } }
              aria-label={ __('Search') }
            >
                <div block="BlogSearchBar" elem="ActionIcon">
                    <SearchIcon />
                </div>
            </button>
        );
    }

    render() {
        const {
            mix, searchCriteria, isSearchVisible, onSearchBarClick, onSearchBarChange, onSearchOutsideClick
        } = this.props;

        return (
            <div mix={ mix }>
                <ClickOutside onClick={ onSearchOutsideClick }>
                    <div block="BlogSearchBar" elem="SearchWrapper" aria-label={ __('Search') }>
                        <div block="BlogSearchBar" elem="Search" mods={ { isActive: isSearchVisible } }>
                            <h6>{ __('Search posts') }</h6>
                            <input
                              id="posts-search-field"
                              placeholder="Search"
                              block="BlogSearchBar"
                              elem="SearchField"
                              onClick={ onSearchBarClick }
                              onChange={ onSearchBarChange }
                              value={ searchCriteria }
                              mods={ { type: 'searchField' } }
                            />
                            { this.renderSearchButton() }
                            { this.renderCloseButton() }
                            <BlogSearchOverlay
                              searchCriteria={ searchCriteria }
                              onSearchOutsideClick={ onSearchOutsideClick }
                              isSearchVisible={ isSearchVisible }
                            />
                        </div>
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default BlogSearchBarComponent;
