/* eslint-disable @scandipwa/scandipwa-guidelines/file-structure */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import LinkedProductsDispatcher from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { DeviceType } from 'Type/Device.type';
import { HistoryType } from 'Type/Router.type';
import { scrollToTop } from 'Util/Browser';

import { CategoriesDispatcher } from '../../store/Categories';
import { PostsDispatcher } from '../../store/Posts';
import { PostsDetailsDispatcher } from '../../store/PostsDetails';
import { TagsDispatcher } from '../../store/Tags';
import { getBlogPath, getBlogTitle } from '../../util/Blog';
import PostsDetails from './PostsDetails.component';
import { POSTS_DETAILS } from './PostsDetails.config';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace Satisfly/MagefanBlog/Route/PostsDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    posts: state.PostsReducer.posts,
    post: state.PostsDetailsReducer.post,
    categories: state.CategoriesReducer.categories,
    tags: state.CategoriesReducer.tags,
    device: state.ConfigReducer.device
});

/** @namespace Satisfly/MagefanBlog/Route/PostsDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestPosts: (options) => PostsDispatcher.handleData(dispatch, options),
    // eslint-disable-next-line max-len
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)),
    requestPost: (options) => {
        PostsDetailsDispatcher.handleData(dispatch, options);
        LinkedProductsDispatcher.clearLinkedProducts(dispatch);
    },
    requestCategories: (options) => CategoriesDispatcher.handleData(dispatch, options),
    requestTags: (options) => TagsDispatcher.handleData(dispatch, options),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName))
});

/** @namespace Satisfly/MagefanBlog/Route/PostsDetails/Container */
export class PostsDetailsContainer extends PureComponent {
    static propTypes = {
        requestCategories: PropTypes.func.isRequired,
        requestTags: PropTypes.func.isRequired,
        requestPost: PropTypes.func.isRequired,
        requestPosts: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        posts: PropTypes.shape({
            // eslint-disable-next-line react/forbid-prop-types
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        post: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                handle: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        history: HistoryType.isRequired,
        device: DeviceType.isRequired
    };

    containerFunctions = {
        postIsLoaded: this.postIsLoaded.bind(this),
        closeOverlay: this.closeOverlay.bind(this)
    };

    componentDidMount() {
        this.requestPosts();
        this.storePathname();
        this.requestCategories();
        this.requestTags();

        const { setHeaderState, history } = this.props;

        setHeaderState({
            name: POSTS_DETAILS,
            title: getBlogTitle(),
            onBackClick: () => history.goBack()
        });

        scrollToTop();
    }

    componentDidUpdate() {
        const {
            posts: { items, count }
        } = this.props;
        const { isLoaded } = this.state;

        this.updateBreadcrumbs();
        this.updatePostsCount();
        this.updatePosts();

        if (!isLoaded && items.length === count && items.length) {
            this.requestPost();
        }

        this.updatePage();
        this.updateMeta();
        scrollToTop();
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isLoaded: false,
            postsCount: 0,
            isCategoryOrTagOverlayOpen: false,
            oldHandle: '',
            isPostMatch: false
        };

        this.options = {
            postOptions: {
                getDescription: true,
                getMedia: true,
                getRelated: true
            }
        };
    }

    updatePosts() {
        const {
            posts: { items, count }
        } = this.props;

        if (items.length < count) {
            this.requestPosts();
        }
    }

    requestPosts() {
        const { requestPosts } = this.props;
        const { postsCount } = this.state;

        if (!postsCount) {
            requestPosts({ filter: {} });
        } else {
            requestPosts({ pageSize: postsCount, filter: {} });
        }
    }

    updatePostsCount() {
        const {
            posts: { count }
        } = this.props;

        this.setState({ postsCount: count });
    }

    requestPost() {
        const {
            requestPost,
            posts: { items }
        } = this.props;
        const { postOptions } = this.options;

        this.setState({ isLoaded: true });

        const matchedItem = Object.entries(items).find(([, item]) => item.identifier === this.getUrlParam());

        if (matchedItem) {
            requestPost({ ...postOptions, id: this.getUrlParam() });
            this.setState({ isPostMatch: true });
            return null;
        }

        return null;
    }

    requestCategories() {
        const { requestCategories } = this.props;

        requestCategories();
    }

    requestTags() {
        const { requestTags } = this.props;

        requestTags();
    }

    getUrlParam() {
        const {
            match: {
                params: { handle }
            }
        } = this.props;

        return handle;
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs, post } = this.props;
        const { title } = post;

        if (!title) {
            return;
        }

        const breadcrumbs = [
            {
                name: title
            },
            {
                url: `/${getBlogPath()}`,
                name: getBlogTitle()
            }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    updatePage() {
        const { oldHandle } = this.state;

        if (oldHandle !== this.getUrlParam()) {
            this.requestPosts();
            this.requestPost();
            this.setHeaderState();
            this.storePathname();
            scrollToTop();
        }
    }

    updateMeta() {
        const {
            post: {
                title, meta_title, meta_description, meta_keywords
            },
            updateMeta
        } = this.props;

        updateMeta({
            title,
            meta_title,
            meta_description,
            meta_keywords,
            canonical_url: this.getCanonicalUrl() || ''
        });
    }

    storePathname() {
        this.setState({ oldHandle: this.getUrlParam() });
    }

    getCanonicalUrl() {
        const {
            post: { identifier }
        } = this.props;

        if (!identifier) {
            return null;
        }

        const blogPath = getBlogPath();

        return `${window.location.origin}/${blogPath}/${identifier}`;
    }

    postIsLoaded() {
        const {
            post: { identifier }
        } = this.props;

        if (this.getUrlParam() === identifier) {
            return true;
        }

        return false;
    }

    setHeaderState(inUpd = false) {
        const { setHeaderState, history } = this.props;
        const { isCategoryOrTagOverlayOpen } = this.state;

        setHeaderState({
            name: POSTS_DETAILS,
            title: getBlogTitle(),
            onBackClick: () => (inUpd && isCategoryOrTagOverlayOpen ? this.closeOverlay() : history.goBack())
        });
    }

    closeOverlay() {
        const { setHeaderState, history } = this.props;

        this.setState({ isCategoryOrTagOverlayOpen: false });

        setHeaderState({
            name: POSTS_DETAILS,
            onBackClick: () => history.goBack()
        });

        scrollToTop();
    }

    containerProps = () => {
        const { isCategoryOrTagOverlayOpen, isPostMatch, isLoaded } = this.state;
        const { device, post, match } = this.props;

        return {
            isCategoryOrTagOverlayOpen,
            device,
            post,
            match,
            isPostMatch,
            isLoaded
        };
    };

    render() {
        return <PostsDetails { ...this.containerProps() } { ...this.containerFunctions } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsDetailsContainer);
