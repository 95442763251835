/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    MyAccountCreateAccount as SourceMyAccountCreateAccount
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import history from 'Util/History';
import { validatePassword } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace Satisfly/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccount extends SourceMyAccountCreateAccount {
    renderCreateAccountPersonalInfoFields() {
        const { newsletterActive } = this.props;
        const { location: { state: { firstName = '', lastName = '' } = {} } } = history;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('First Name') }
                  attr={ {
                      id: 'firstname',
                      name: 'firstname',
                      defaultValue: firstName,
                      placeholder: __('Your first name'),
                      autocomplete: 'given-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Last Name') }
                  attr={ {
                      id: 'lastname',
                      name: 'lastname',
                      defaultValue: lastName,
                      placeholder: __('Your last name'),
                      autocomplete: 'family-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  addRequiredTag
                />
                { newsletterActive ? this.renderSubscribeToNewsletter() : null }
            </fieldset>
        );
    }

    renderCreateAccountSignUpInfoFields() {
        const { location: { state: { email = '' } = {} } } = history;
        const { range, minimunPasswordCharacter } = this.props;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      defaultValue: email,
                      placeholder: __('Your email name'),
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
                <div block="MyAccountOverlay" elem="PasswordBlock">
                    <Field
                      type={ FIELD_TYPE.password }
                      label={ __('Password') }
                      attr={ {
                          id: 'password',
                          name: 'password',
                          placeholder: __('Enter your password'),
                          autocomplete: 'new-password'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.password,
                          match: (value) => {
                              const email = document.getElementById('email');

                              if (value && email.value === value) {
                                  return __('Passwords can\'t be the same as email!');
                              }

                              return validatePassword(value, range, minimunPasswordCharacter);
                          }
                      } }
                      addRequiredTag
                    />
                    <Field
                      type={ FIELD_TYPE.password }
                      label={ __('Confirm password') }
                      attr={ {
                          id: 'confirm_password',
                          name: 'confirm_password',
                          placeholder: __('Retype your password'),
                          autocomplete: 'new-password'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.password,
                          match: (value) => {
                              const password = document.getElementById('password');

                              return value && password.value === value;
                          },
                          customErrorMessages: {
                              onMatchFail: __('Passwords do not match!')
                          }
                      } }
                      addRequiredTag
                    />
                </div>
            </fieldset>
        );
    }
}

export default MyAccountCreateAccount;
