/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Satisfly/MagefanBlog/Util/Blog/getBlogPath */
// eslint-disable-next-line import/prefer-default-export
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace Satisfly/MagefanBlog/Util/Date/formatDate */
export const getBlogPath = () => 'blog';

/** @namespace Satisfly/MagefanBlog/Util/Blog/getBlogTitle */
export const getBlogTitle = () => __('Blog');
