/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { UPDATE_SINGLE_POST } from './PostsDetails.action';

/** @namespace Satisfly/MagefanBlog/Store/PostsDetails/Reducer/getInitialState */
export const getInitialState = () => ({
    post: {}
});

/** @namespace Satisfly/MagefanBlog/Store/PostsDetails/Reducer/PostsDetailsReducer */
export const PostsDetailsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SINGLE_POST:
        const { post } = action;

        return {
            ...state,
            post
        };

    default:
        return state;
    }
};

export default PostsDetailsReducer;
