/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import TagManager from 'react-gtm-module';

import getStore from 'Util/Store';

import { checkIsEventEnable } from './checkIsEventEnable';
import { ECOMMERCE } from './events';
import { injectAntiFlicker, injectGoogleOptimize } from './googleOptimize';
import { waitForCallback } from './wait';

// eslint-disable-next-line fp/no-let, @scandipwa/scandipwa-guidelines/export-level-one
let isGtmInitialized = false;

/** @namespace Satisfly/Gtm/Util/Push/gtag */
export function gtag() {
    window.dataLayer.push(arguments);
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const beforeInitQue = [];

/** @namespace Satisfly/Gtm/Util/Push/getCookie */
export const getCookie = (name) => {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (!match) {
        return '';
    }

    return decodeURIComponent(match[2]).split(',');
};

/** @namespace Satisfly/Gtm/Util/Push/isGoogleAnalyticsCookieAllowed */
export const isGoogleAnalyticsCookieAllowed = () => {
    const disallowedCookies = getCookie('amcookie_disallowed');
    const allowedCookies = getCookie('amcookie_allowed');
    const googleAnalyticsCookieName = '_ga';

    return !(disallowedCookies.includes(googleAnalyticsCookieName) || !allowedCookies);
};

/** @namespace Satisfly/Gtm/Util/Push/getIsConsentAccepted */
// eslint-disable-next-line max-len
export const getIsConsentAccepted = (disallowedConsents, consent) => !disallowedConsents.includes(consent);

/** @namespace Satisfly/Gtm/Util/Push/setGTMSettings */
export const setGTMSettings = () => {
    const disallowedCookies = getCookie('amcookie_disallowed');

    gtag('consent', 'update', {
        analytics_storage: getIsConsentAccepted(disallowedCookies, 'analytics_storage') ? 'granted' : 'denied',
        ad_storage: getIsConsentAccepted(disallowedCookies, 'ad_storage') ? 'granted' : 'denied',
        ad_user_data: getIsConsentAccepted(disallowedCookies, 'ad_user_data') ? 'granted' : 'denied',
        ad_personalization: getIsConsentAccepted(disallowedCookies, 'ad_personalization') ? 'granted' : 'denied'
    });

    gtag('set', 'ads_data_redaction', !getIsConsentAccepted(disallowedCookies, 'ad_storage'));
};

/** @namespace Satisfly/Gtm/Util/Push/setDefaultGTMSettings */
export const setDefaultGTMSettings = () => {
    gtag('consent', 'default', {
        analytics_storage: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        wait_for_update: 500
    });

    gtag('set', 'ads_data_redaction', true);
    gtag('set', 'url_passthrough', true);
};

/** @namespace Satisfly/Gtm/Util/Push/initGTM */
export const initGTM = () => {
    const {
        gtm_id: gtmId
    } = getStore().getState().ConfigReducer.gtm;

    window.dataLayer = window.dataLayer || [];

    setDefaultGTMSettings();

    // GTM needs to be init before push
    // Que needs to be executed
    TagManager.initialize({ gtmId });

    setGTMSettings();

    beforeInitQue.forEach((qData) => {
        TagManager.dataLayer({
            dataLayer: qData
        });
    });

    // eslint-disable-next-line no-console
    console.log('GTM que was emptied');

    isGtmInitialized = true;

    injectGoogleOptimize();
    injectAntiFlicker();

    beforeInitQue.splice(0, beforeInitQue.length);
    // ^^^ Clear the beforeInitQue
};

/** @namespace Satisfly/Gtm/Util/Push/pushToDataLayer */
export const pushToDataLayer = async (data) => {
    await waitForCallback(() => getStore().getState().ConfigReducer?.gtm?.enabled);
    // TODO: ^^^ if connection is too poor it might change the behavior and delay the initialization until next event
    // vvv Push request must be queued
    beforeInitQue.push(data);
    // ^^^ This will be used for when configReducer is still loading or gtm is not initialized yet

    const {
        enabled: isEnabled
        // vvv These values are injected using GTM
    } = getStore().getState().ConfigReducer.gtm;

    if (isEnabled === undefined) {
        // Config is not yet obtained
        return;
    }

    if (isEnabled === false) {
        // GTM is disabled, skip GTM
        injectGoogleOptimize();
        injectAntiFlicker();

        return;
    }

    const { event } = data;

    if (!checkIsEventEnable(event)) {
        return;
    }

    if (isGtmInitialized === false) {
        if (!isGoogleAnalyticsCookieAllowed()) {
            return;
        }

        initGTM();

        return;
    }

    // vvv We need to remove added data from array, to not make the app heavy
    beforeInitQue.pop();

    const dataKeys = Object.keys(data);

    if (dataKeys.indexOf(ECOMMERCE) !== -1) {
        // https://developers.google.com/tag-platform/devguides/datalayer?hl=pl#reset
        window.dataLayer.push(function () {
            this.reset();
        });
    }

    // ^^^ We don't need the items in queue anymore so we need to pop it (since our item is the last item)
    TagManager.dataLayer({
        dataLayer: data
    });
};

document.addEventListener('consentSavedFired', async () => {
    await waitForCallback(() => getStore().getState().ConfigReducer?.gtm?.enabled);

    const {
        enabled: isEnabled
        // vvv These values are injected using GTM
    } = getStore().getState().ConfigReducer.gtm;

    if (isEnabled === false) {
        return;
    }

    if (isGtmInitialized === false && isGoogleAnalyticsCookieAllowed()) {
        initGTM();
        return;
    }

    if (isGtmInitialized === true) {
        setGTMSettings();
    }
});
