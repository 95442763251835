/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import { noopFn } from 'Util/Common';

import { GDPR_SETTINGS_MODAL_POPUP_KEY } from '../../config';
import { cookiesGroupDataType } from '../../type/gdpr.type';
import CookiesDetailsModal from '../CookiesDetailsModal';
import CookiesItem from '../CookiesItem';

import './SettingsModal.style';

/** @namespace Scandiweb/AmastyGdpr/Component/SettingsModal/Component */
export class SettingsModalComponent extends PureComponent {
    static propTypes = {
        /** array of cookie group data */
        groupData: PropTypes.arrayOf(cookiesGroupDataType),
        /** function that handles updating selected cookies changes */
        handleDoneOnClick: PropTypes.func.isRequired,
        /** boolean that controls the `isStatic` prop of the popup */
        isSettingsModalVisible: PropTypes.bool.isRequired,
        dismissSettingsModal: PropTypes.func
    };

    static defaultProps = {
        groupData: [],
        dismissSettingsModal: noopFn
    };

    renderDoneButton() {
        const {
            handleDoneOnClick
        } = this.props;

        return (
            <button
              className="Button"
              mix={ { block: 'GDPRSettingsModal', elem: 'DoneButton' } }
              onClick={ handleDoneOnClick }

            >
                { __('Accept') }
            </button>
        );
    }

    renderCookiesItem = (groupData) => (
        <CookiesItem groupData={ groupData } />
    );

    render() {
        const {
            groupData,
            isSettingsModalVisible,
            dismissSettingsModal
        } = this.props;

        return (
            <div>
                <Popup
                  mix={ { block: 'GDPRSettingsModal', elem: 'Wrapper' } }
                  id={ GDPR_SETTINGS_MODAL_POPUP_KEY }
                  // vvv allows the user to click outside the popup without dismissing it.
                  clickOutside={ false }
                  isStatic={ isSettingsModalVisible }
                  isClosableOnKeydown={ false }
                  onClose={ dismissSettingsModal }
                >
                    { /* this type of string is for translations */ }
                    <p>{ __('Please select and accept your cookies groups') }</p>
                    { groupData.map(this.renderCookiesItem) }
                    { this.renderDoneButton() }
                </Popup>
                <CookiesDetailsModal />
            </div>
        );
    }
}

export default SettingsModalComponent;
