/* eslint-disable @scandipwa/scandipwa-guidelines/file-structure */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import {
    ContentWrapper as SourceContentWrapper
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';

/**
 * Content Wrapper
 * @class ContentWrapper
 * @namespace Satisfly/Component/ContentWrapper/Component */
export class ContentWrapper extends SourceContentWrapper {
    static propTypes = {
        ...super.propTypes,
        elementId: PropTypes.string,
        showActiveTab: PropTypes.string
    };

    static defaultProps = {
        ...super.defaultProps
    };

    renderContentWrapper() {
        const {
            children, wrapperMix, elementId, showActiveTab
        } = this.props;

        return (
            <div block="ContentWrapper" mix={ wrapperMix } id={ elementId } showActiveTab={ showActiveTab }>
                { children }
            </div>
        );
    }
}

export default ContentWrapper;
