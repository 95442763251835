/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    ORDER_SHIPMENTS
} from 'Component/MyAccountOrder/MyAccountOrder.config';
import {
    MyAccountOrderItemsTable as SourceMyAccountOrderItemsTable
} from 'SourceComponent/MyAccountOrderItemsTable/MyAccountOrderItemsTable.component';

import './MyAccountOrderItemsTable.override.style';

/** @namespace Satisfly/Component/MyAccountOrderItemsTable/Component */
export class MyAccountOrderItemsTable extends SourceMyAccountOrderItemsTable {
    renderOrderTitleAndActions() {
        return (
            <div block="MyAccountOrderItemsTable" elem="OrderActions">
                { this.renderPrintAction() }
            </div>
        );
    }

    renderSubtotalHeading() {
        const { activeTab } = this.props;

        if (activeTab === ORDER_SHIPMENTS) {
            return null;
        }

        return (
            <th
              block="MyAccountOrderItemsTable"
              elem="Subtotal"
            >
                { __('Sum') }
            </th>
        );
    }

    renderItemsHeading() {
        return (
            <tr
              block="MyAccountOrderItemsTable"
              elem="Headings"
            >
                <th
                  block="MyAccountOrderItemsTable"
                  elem="Name"
                >
                    { __('Product') }
                </th>
                <th
                  block="MyAccountOrderItemsTable"
                  elem="ProductSku"
                >
                    { __('SKU') }
                </th>
                { this.renderPriceHeading() }
                <th
                  block="MyAccountOrderItemsTable"
                  elem="Quantity"
                >
                    { __('Qty') }
                </th>
                { this.renderSubtotalHeading() }
                { this.renderRefundsTableHeading() }
            </tr>
        );
    }
}

export default MyAccountOrderItemsTable;
