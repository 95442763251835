/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { UPDATE_CONFIG } from 'Store/Config/Config.action';

const addGTMToState = (args, callback) => ({
    ...callback(...args),
    gtm: []
});

const getGTMFromAction = (args, callback) => {
    const [, action] = args;
    const { type, config: { gtm } = {} } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        gtm
    };
};

export default {
    'Store/Config/Reducer/getInitialState': {
        function: addGTMToState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getGTMFromAction
    }
};
