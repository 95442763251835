/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGdpr/Query/PrivacySettings/Query */
export class PrivacySettingsQuery {
    getData() {
        return new Field('gdpr')
            .addField(this.getGdprPrivacySettingsFields());
    }

    getGdprPrivacySettingsFields() {
        return new Field('privacySettings')
            .addFieldList([
                this.getAnonymiseField(),
                this.getDeleteField(),
                this.getDownloadField(),
                this.getPoliciesTextField(),
                this.getDpoInfoField()
            ]);
    }

    getAnonymiseField() {
        return new Field('anonymise')
            .addFieldList(this.getAnonymiseFields());
    }

    getAnonymiseFields() {
        return [
            'action',
            'actionCode',
            'checkboxDataValidate',
            'checkboxText',
            'content',
            'cssModifier',
            'hasCheckbox',
            'hidePassword',
            'needPassword',
            'submitText',
            'title'
        ];
    }

    getDeleteField() {
        return new Field('delete')
            .addFieldList(this.getDeleteFields());
    }

    getDeleteFields() {
        return [
            'action',
            'actionCode',
            'checkboxDataValidate',
            'checkboxText',
            'content',
            'cssModifier',
            'hasCheckbox',
            'hidePassword',
            'needPassword',
            'submitText',
            'title'
        ];
    }

    getDownloadField() {
        return new Field('download')
            .addFieldList(this.getDownloadFields());
    }

    getDownloadFields() {
        return [
            'action',
            'actionCode',
            'checkboxText',
            'content',
            'cssModifier',
            'hasCheckbox',
            'hidePassword',
            'needPassword',
            'submitText',
            'title'
        ];
    }

    getPoliciesTextField() {
        return new Field('policies_text')
            .addFieldList(this.getPoliciesTextFields());
    }

    getPoliciesTextFields() {
        return [
            'actionCode',
            'cssModifier',
            'isCookieEnabled',
            'policiesText',
            'title'
        ];
    }

    getDpoInfoField() {
        return new Field('dpo_info')
            .addFieldList(this.getDpoInfoFields());
    }

    getDpoInfoFields() {
        return [
            'actionCode',
            'cssModifier',
            'content',
            'title'
        ];
    }
}

export default new PrivacySettingsQuery();
