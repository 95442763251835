/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    MyAccount as SourceMyAccount,
    MyAccountAddressBook,
    MyAccountDashboard,
    MyAccountDownloadable,
    MyAccountMyOrders,
    MyAccountMyWishlist,
    MyAccountNewsletterSubscription
} from 'SourceRoute/MyAccount/MyAccount.component';

import './MyAccount.override.style';

export {
    MyAccountAddressBook,
    MyAccountDashboard,
    MyAccountDownloadable,
    MyAccountMyOrders,
    MyAccountMyWishlist,
    MyAccountNewsletterSubscription
};

/** @namespace Satisfly/Route/MyAccount/Component */
export class MyAccount extends SourceMyAccount {}

export default MyAccount;
