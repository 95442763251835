/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    MyAccountOrderItemsTableRow as SourceMyAccountOrderItemsTableRow
} from 'SourceComponent/MyAccountOrderItemsTableRow/MyAccountOrderItemsTableRow.component';
import { formatPrice } from 'Util/Price';

import './MyAccountOrderItemsTableRow.override.style';

/** @namespace Satisfly/Component/MyAccountOrderItemsTableRow/Component */
export class MyAccountOrderItemsTableRow extends SourceMyAccountOrderItemsTableRow {
    renderPrice(value, currency, title) {
        return (
            <td data-th={ title }>
                { formatPrice(value, currency) }
            </td>
        );
    }

    renderQty([, qty], index) {
        if (qty === 0) {
            return null;
        }

        return (
            <li key={ index }>{ qty }</li>
        );
    }
}

export default MyAccountOrderItemsTableRow;
