/* eslint-disable @scandipwa/scandipwa-guidelines/file-structure */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    MyAccountOrderTab as SourceMyAccountOrderTab
} from 'SourceComponent/MyAccountOrderTab/MyAccountOrderTab.component';

import { TAB_EXCLUDE } from './MyAccountOrderTab.config';

/** @namespace Satisfly/Component/MyAccountOrderTab/Component */
export class MyAccountOrderTab extends SourceMyAccountOrderTab {
    render() {
        const {
            title, isActive, handleClickOnTab, tabName
        } = this.props;

        if (TAB_EXCLUDE === tabName) {
            return null;
        }

        return (
            <li
              block="MyAccountOrderTab"
              elem="Item"
              mods={ { isActive } }
            >
                <button
                  mix={ { block: 'MyAccountOrderTab', elem: 'Button' } }
                  onClick={ handleClickOnTab }
                >
                    { title }
                </button>
            </li>
        );
    }
}

export default MyAccountOrderTab;
