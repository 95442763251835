/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    MyAccountAddressTable as SourceMyAccountAddressTable
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

/** @namespace Satisfly/Component/MyAccountAddressTable/Component */
export class MyAccountAddressTable extends SourceMyAccountAddressTable {
    renderActions() {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            address: { default_billing, default_shipping }
        } = this.props;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <div block="MyAccountAddressTable" elem="ActionBar">
                <button
                  block="Button"
                  onClick={ onEditClick }
                  mods={ { isHollow: true, medium: true } }
                >
                    { __('Edit address') }
                </button>
                <button
                  block="Button"
                  mods={ { likeLink: true } }
                  onClick={ onDeleteClick }
                  disabled={ isDeleteAllowed }
                  title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : __('Delete this address') }
                >
                    { __('Delete') }
                </button>
            </div>
        );
    }
}

export default MyAccountAddressTable;
