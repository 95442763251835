/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { fireGeneralEvent } from '../../event/general';

// vvv Added a component to use location hook
function GTMGeneralEventTracker() {
    const location = useLocation();

    useEffect(() => {
        fireGeneralEvent();
    }, [location.pathname]);

    useEffect(() => {
        fireGeneralEvent();
    }, []);

    return null;
}

const addGTMGeneralEventTracker = (args, callback) => (
    <>
        { callback(args) }
        <GTMGeneralEventTracker />
    </>
);

export default {
    'Satisfly/Component/Router/Component': {
        'member-function': {
            renderRouterContent: addGTMGeneralEventTracker
        }
    }
};
