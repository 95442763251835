/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import BlogRecentPosts from '../component/BlogRecentPosts';

/** @namespace ScandiPWA/Blog/Plugin/Component/Html/Plugin/stateMap */
export class HtmlComponentPlugin {
     aroundRules = (originalMember) => ([
         ...originalMember,
         {
             query: { attribs: ['data-recent-posts'] },
             replace: this.replaceRecentPosts
         }
     ]);

     replaceRecentPosts() {
         return <BlogRecentPosts />;
     }
}

const { aroundRules } = new HtmlComponentPlugin();

export const config = {
    'Component/Html/Component': {
        'member-property': {
            rules: aroundRules
        }
    }
};

export default config;
