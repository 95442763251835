/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    GDPR_COOKIES_SETTINGS_TEXT
} from '../../config';

import './GDPRFooterLink.style.scss';

/** @namespace Scandiweb/AmastyGdpr/Component/GDPRFooterLink/Component */
export class GDPRFooterLinkComponent extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired
    };

    render() {
        const { showPopup } = this.props;

        return (
            <div block="GDPRFooterLink" key="GDPRFooterLink_Settings">
                    <button
                      block="GDPRFooterLink"
                      elem="Text"
                      onClick={ showPopup }
                    >
                        { GDPR_COOKIES_SETTINGS_TEXT }
                    </button>
            </div>
        );
    }
}

export default GDPRFooterLinkComponent;
