/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { addWindowOnBeforeUnloadEventListener, addWindowOnLoadEventListener } from '../../event/pageReload';

const addWindowEventListeners = (args, callback) => {
    callback(...args);
    addWindowOnLoadEventListener();
    addWindowOnBeforeUnloadEventListener();
    // ^^ Events for this parts are window.onLoad and window.onBeforeUnload ...
    // ^^ ... and we need to listen for this event ASAP to make the timeOnPage more accurate.
};

export default {
    'Component/App/Component': {
        'member-function': {
            __construct: addWindowEventListeners
        }
    }
};
