/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import GoogleTagManagerQuery from '../../query/Config.query';

const addGTMToRequest = (args, callback) => ([
    ...callback(...args),
    GoogleTagManagerQuery.getGtmField()
]);

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addGTMToRequest
        }
    }
};
