/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_TAGS = 'UPDATE_TAGS';
export const NO_TAGS = 'NO_TAGS';

/**
 * Update tags
 * @param tags
 * @returns {{tags: *, type: string}}
 * @namespace Satisfly/MagefanBlog/Store/Tags/Action/updateTags */
export const updateTags = (tags) => ({
    type: UPDATE_TAGS,
    tags,
    isLoaded: true
});

/**
 * @param message
 * @returns {{type: string, message: *}}
 * @namespace Satisfly/MagefanBlog/Store/Tags/Action/tagsException */
export const tagsException = (message) => ({
    type: NO_TAGS,
    message
});
