/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getCartId } from '@scandipwa/scandipwa/src/util/Cart';

import BrowserDatabase from 'Util/BrowserDatabase';

import { getCartItemsData } from '../../data/cart';
import {
    CART_PRODUCTS,
    DL_VAL_CHECKOUT_BILLING_STEP,
    DL_VAL_CHECKOUT_SHIPPING_STEP,
    STEP_1_LABEL,
    STEP_2_LABEL
} from '../../data/checkout';
import { fireCheckoutEvent, fireCheckoutOptionEvent, firePurchaseEvent } from '../../event/checkout';

const addFireCheckoutOptionEventForBilling = (args, callback, instance) => {
    callback(...args);

    const { paymentMethod } = instance.state;

    fireCheckoutOptionEvent(
        DL_VAL_CHECKOUT_BILLING_STEP,
        paymentMethod
    );
};

const addFireCheckoutEventForBilling = (args, callback) => {
    callback(...args);
    fireCheckoutEvent(DL_VAL_CHECKOUT_BILLING_STEP, STEP_2_LABEL);
};

const addFireCheckoutOptionEventForShipping = (args, callback, instance) => {
    callback(...args);

    const { selectedShippingMethod: { carrier_title } } = instance.state;

    fireCheckoutOptionEvent(
        DL_VAL_CHECKOUT_SHIPPING_STEP,
        carrier_title
    );
};

const getProducts = async () => {
    const data = await getCartItemsData();

    return data;
};

const addFireCheckoutEventForShipping = (args, callback) => {
    callback(...args);
    BrowserDatabase.deleteItem(CART_PRODUCTS);
    getProducts().then((products) => {
        BrowserDatabase.setItem(
            products,
            CART_PRODUCTS
        );
    });

    fireCheckoutEvent(DL_VAL_CHECKOUT_SHIPPING_STEP, STEP_1_LABEL);
};

const addFirePurchaseEvent = (args, callback) => {
    const [order_id] = args;
    const guestQuoteId = getCartId();

    firePurchaseEvent(order_id, guestQuoteId);

    callback(...args);
};

const addFirePurchaseEventP24 = (args, callback) => {
    const [order_id] = args;
    const guestQuoteId = getCartId();

    firePurchaseEvent(order_id, guestQuoteId).then(() => {
        callback(...args);
    });
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: addFireCheckoutOptionEventForBilling,
            componentDidMount: addFireCheckoutEventForBilling
        }
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess: addFireCheckoutOptionEventForShipping,
            componentDidMount: addFireCheckoutEventForShipping
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            setDetailsStep: addFirePurchaseEvent,
            handleRedirect: addFirePurchaseEventP24
        }
    }
};
