/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import LinkedProductsDispatcher from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';

import BlogPostQuery from '../../query/BlogPost.query';
import { updateSinglePost } from './PostsDetails.action';

/** @namespace Satisfly/MagefanBlog/Store/PostsDetails/Dispatcher */
export class PostsDetailsDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(data, dispatch) {
        const { post } = data;
        if (post) {
            if (Object.keys(post).length !== 0) {
                const post_links = [];

                if (post_links.length !== 0) {
                    LinkedProductsDispatcher.handleData(dispatch, post_links);
                }
            }

            dispatch(updateSinglePost(post));
        }
    }

    onError({ message }, dispatch) {
        dispatch(showNotification('error', __('Error fetching post details!'), message));
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options) {
        return BlogPostQuery.getQuery(options);
    }

    buildProductLink(productSKU, index) {
        return {
            link_type: 'related',
            linked_product_sku: productSKU,
            position: index
        };
    }
}

export default new PostsDetailsDispatcher();
