/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import getStore from 'Util/Store';

import { getCartItemsData } from './cart';
import { DL_VAL_PAGE_CHECKOUT } from './page';

export const DL_VAL_CHECKOUT_SHIPPING_STEP = 1;
export const DL_VAL_CHECKOUT_BILLING_STEP = 2;
export const STEP_1_LABEL = 'address and shipping';
export const STEP_2_LABEL = 'payment';
export const CART_PRODUCTS = 'CART_PRODUCTS';

/** @namespace Satisfly/Gtm/Data/Checkout/getCheckoutEventData */
export const getCheckoutEventData = async (step, option) => {
    const currencyCode = getStore().getState().ConfigReducer?.default_display_currency_code;

    return {
        ecommerce: {
            currencyCode,
            checkout: {
                actionField: {
                    step,
                    option,
                    action: DL_VAL_PAGE_CHECKOUT
                },
                products: await getCartItemsData()
            }
        }
    };
};

/** @namespace Satisfly/Gtm/Data/Checkout/getCheckoutOptionEventData */
export const getCheckoutOptionEventData = async (step, option) => ({
    ecommerce: {
        checkout_option: {
            actionField: {
                option,
                step,
                action: DL_VAL_PAGE_CHECKOUT
            }
        }
    }
});
