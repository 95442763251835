/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    MyAccountTabList as SourceMyAccountTabList
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.component';

import './MyAccountTabList.override.style';

/** @namespace Satisfly/Component/MyAccountTabList/Component */
export class MyAccountTabList extends SourceMyAccountTabList {
    renderLogoutTab() {
        const { handleLogout } = this.props;

        return (
            <li
              key="logout"
              block="MyAccountTabListItem"
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  mix={ { block: 'Button', mods: { likeLink: true } } }
                  onClick={ handleLogout }
                  role="link"
                >
                    { __('Logout') }
                </button>
            </li>
        );
    }
}

export default MyAccountTabList;
