/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { QueryDispatcher as SourceQueryDispatcher } from 'SourceUtil/Request/QueryDispatcher';
import { Field, prepareQuery } from 'Util/Query';
import { executeGet, listenForBroadCast } from 'Util/Request/Request';

export const ONE_MONTH_IN_SECONDS = 2592000;
export const FIVE_MINUTES_IN_SECONDS = 300;

/** @namespace Satisfly/Util/Request/QueryDispatcher */
export class QueryDispatcher extends SourceQueryDispatcher {
    async handleData(dispatch, options) {
        const { name, cacheTTL } = this;

        const rawQueries = this.prepareRequest(options, dispatch);

        if (!rawQueries) {
            return;
        }

        const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;

        const abort = this.promise && this.controller.abort();

        this.controller = new AbortController();

        try {
            this.promise = await executeGet(prepareQuery(queries), name, cacheTTL, this.controller.signal);
            this.onSuccess(this.promise, dispatch, options);
        } catch (err) {
            if (err.message && !err.message.includes('abort')) {
                this.onError(err, dispatch, options);
            }
        }
        const broadcast = await listenForBroadCast(name);

        this.onUpdate(broadcast, dispatch, options);
    }
}

export default QueryDispatcher;
