/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { DeviceType } from 'Type/Device.type';

import { getBlogPath } from '../../util/Blog';
import { MAX_TAGS } from './BlogTags.config';

import './BlogTags.style';

/** @namespace Satisfly/MagefanBlog/Component/BlogTags/Component */
export class BlogTagsComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        tags: PropTypes.array,
        isLoaded: PropTypes.bool,
        device: DeviceType.isRequired,
        closeOverlay: PropTypes.func.isRequired,
        isTagOverlayOpen: PropTypes.func.isRequired
    };

    static defaultProps = {
        tags: {},
        isLoaded: false
    };

    renderTagField(identifier, title, key) {
        const { closeOverlay } = this.props;
        const blogPath = getBlogPath();
        return (
            <div
              className="BlogTag"
              block="BlogTags"
              elem="Tag"
              key={ key }
            >
                <Link
                  to={ `${identifier === 'all-tags'
                      ? `/${blogPath}`
                      : `${blogPath}?tag=${identifier}`}` }
                  title={ __('View %s', title) }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => (closeOverlay ? closeOverlay(title) : null) }
                >
                    <TextPlaceholder
                      mix={ { block: 'BlogTags', elem: 'TagTitlePlaceholder' } }
                      content={ title }
                      length="medium"
                    />
                </Link>
            </div>
        );
    }

    renderTag(tag, key) {
        const {
            title, identifier, tag_id
        } = tag;

        return this.renderTagField(identifier, title, key, tag_id);
    }

    renderContent() {
        const { tags, isLoaded, device } = this.props;
        const tag = {};

        if (!isLoaded) {
            return Array(MAX_TAGS)
                .fill()
                .map((_, i) => this.renderTag(tag, i));
        }

        if (isLoaded && tags.length === 0) {
            return __('No tags');
        }

        if (tags.length === MAX_TAGS && device.isMobile) {
            tags.push({ tag_id: 11, identifier: 'all-tags', title: 'All Tags' });
            return tags
                .sort((data, { tag_id: id }) => data.tag_id - id)
                .map((tag, key) => this.renderTag(tag, key));
        }

        return tags.map((tag, key) => this.renderTag(tag, key));
    }

    render() {
        const { device, isTagOverlayOpen } = this.props;
        const content = device.isMobile
            ? (
                <div
                  block="BlogTags"
                  elem="Overlay"
                  mods={ { isOpen: isTagOverlayOpen } }
                >
                    { this.renderContent() }
                </div>
            )
            : this.renderContent();

        return (
            <div
              block="BlogTags"
              elem="Wrapper"
              aria-hidden="true"
            >
                <h6 block="BlogTags" elem="Title">
                    { __('Tags') }
                </h6>
                <div
                  block="BlogTags"
                  elem="Content"
                >
                    { content }
                </div>
            </div>
        );
    }
}

export default BlogTagsComponent;
