/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { INVOICE_TYPE, RECEIPT_TYPE } from 'Component/CheckoutBilling/CheckoutBilling.config';
import {
    MyAccountOrderInformation as SourceMyAccountOrderInformation
} from 'SourceComponent/MyAccountOrderInformation/MyAccountOrderInformation.component';

import './MyAccountOrderInformation.override.style';

/** @namespace Satisfly/Component/MyAccountOrderInformation/Component */
export class MyAccountOrderInformation extends SourceMyAccountOrderInformation {
    renderContent() {
        return (
            <div
              block="MyAccountOrderInformation"
              elem="Information"
            >
                    { this.renderShippingAddress() }
                    { this.renderShippingMethod() }
                    { this.renderBillingAddress() }
                    { this.renderPaymentMethods() }
            </div>
        );
    }

    getFormattedStreet(street) {
        let addr = '';
        street.forEach((line, i) => {
            switch (i) {
            case 0:
                addr += line;
                break;
            case 1:
                addr += ` ${line}`;
                break;
            case 2:
                addr += `/${line}`;
                break;
            default:
                break;
            }
        });

        return addr;
    }

    renderAddressContent(address) {
        const {
            firstname, lastname, postcode, city, street, telephone
        } = address;

        return (
            <div block="MyAccountOrderInformation" elem="AddressContent">
                <p>{ `${firstname} ${lastname}` }</p>
                <p>{ `${this.getFormattedStreet(street)}` }</p>
                <p>{ `${postcode} ${city}` }</p>
                <p>{ telephone }</p>
            </div>
        );
    }

    renderCompanyDetails() {
        const {
            order: {
                doc_type,
                doc_type_name,
                billing_address: {
                    company,
                    vat_id
                } = {}
            }
        } = this.props;

        if (doc_type === RECEIPT_TYPE.id) {
            return null;
        }

        return (
            <div block="MyAccountOrderInformation" elem="AddressContent">
                <p block="AddressContent" elem="Subheading">{ __(doc_type_name) }</p>
                { doc_type === INVOICE_TYPE.id
                    ? <p>{ company }</p>
                    : '' }
                <p>{ `${__('VAT Number')} ${vat_id}` }</p>
            </div>
        );
    }

    renderShippingAddress() {
        const { order: { shipping_address } = {} } = this.props;

        if (!shipping_address) {
            return null;
        }

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Column"
              mods={ { type: 'shippingAddress' } }
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Shipping address') }</span>
                    </strong>
                </div>
                { this.renderAddressContent(shipping_address) }
            </div>
        );
    }

    renderBillingAddress() {
        const { order: { billing_address } = {} } = this.props;

        if (!billing_address) {
            return null;
        }

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Column"
              mods={ { type: 'billingAddress' } }
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Billing address') }</span>
                    </strong>
                </div>
                { this.renderCompanyDetails() }
                { this.renderAddressContent(billing_address) }
            </div>
        );
    }

    renderShippingMethod() {
        const { order: { shipping_method } } = this.props;

        if (!shipping_method) {
            return null;
        }

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Column"
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Delivery method') }</span>
                    </strong>
                </div>
                <span
                  block="MyAccountOrderInformation"
                  elem="ShippingMethod"
                >
                    { shipping_method }
                </span>
            </div>
        );
    }

    renderPaymentMethods() {
        const { order: { payment_methods = [] } } = this.props;

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Column"
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Payment method') }</span>
                    </strong>
                </div>
                { payment_methods.map(this.renderPaymentMethod.bind(this)) }
            </div>
        );
    }
}

export default MyAccountOrderInformation;
