/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    RadioButton as SourceRadioButton
} from 'SourceComponent/RadioButtonIcon/RadioButtonIcon.component';

import './RadioButtonIcon.style';

/** @namespace Satisfly/Component/RadioButtonIcon/Component */
export class RadioButton extends SourceRadioButton {
    render() {
        const { isActive } = this.props;

        return (
            <div
              block="RadioButtonIcon"
              mods={ { isActive } }
            />
        );
    }
}

export default RadioButton;
