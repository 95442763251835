/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { withRouter } from 'react-router-dom';

import {
    MyAccountOverlay as SourceMyAccountOverlay
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.component';

import './MyAccountOverlay.override.style';

/** @namespace Satisfly/Component/MyAccountOverlay/Component */
export class MyAccountOverlay extends SourceMyAccountOverlay {}

export default withRouter(
    MyAccountOverlay
);
