/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { UPDATE_POSTS } from './Posts.action';

export const emptyPosts = {
    items: [],
    count: 0
};

export const initialState = {
    posts: emptyPosts,
    post: {}
};

/** @namespace Satisfly/MagefanBlog/Store/Posts/Reducer/PostsReducer */
export const PostsReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_POSTS:
        const {
            posts: {
                items, total_count: count, total_pages: totalPages
            },
            isLoaded
        } = action;

        return {
            ...state,
            posts: {
                items,
                count,
                totalPages
            },
            isLoaded
        };
    default:
        return state;
    }
};

export default PostsReducer;
