/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceUtil/Cart/Cart';

export const GUEST_QUOTE_ID = 'guest_quote_id';

/** @namespace Satisfly/Util/Cart/getItemsCountLabel */
export const getItemsCountLabel = (items_qty) => {
    if (items_qty === 1) {
        return __('1 item');
    }

    // eslint-disable-next-line no-magic-numbers
    if (items_qty > 1 && items_qty < 5) {
        return __('%s items_plural', items_qty);
    }

    return __('%s items', items_qty || 0);
};
