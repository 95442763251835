/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';

import BlogCategories from '../../query/BlogCategories.query';
import { updateCategories } from './Categories.action';

/** @namespace Satisfly/MagefanBlog/Store/Categories/Dispatcher */
export class CategoriesDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ categories }, dispatch) {
        if (categories) {
            dispatch(updateCategories(categories));
        }
    }

    onError({ message }, dispatch) {
        if (message) {
            dispatch(showNotification('error', __('Error fetching Categories!'), message));
        }
    }

    prepareRequest(options) {
        return BlogCategories.getQuery(options);
    }
}

export default new CategoriesDispatcher();
