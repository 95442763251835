/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { SHOW_NOTIFICATION } from 'SourceStore/Notification/Notification.action';

import { fireErrorMessageEvent } from '../../event/errorMessage';

const addFireErrorMessageEvent = (args, callback) => {
    const [, action] = args;

    if (action.type !== SHOW_NOTIFICATION) {
        return callback(...args);
    }

    const { msgType, msgText } = action;

    if (msgType === 'error') {
        fireErrorMessageEvent(msgText);
    }

    return callback(...args);
};

export default {
    'Store/Notification/Reducer/NotificationReducer': {
        function: addFireErrorMessageEvent
    }
};
