/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import { convertDateFormat } from '../../util/Date';
import { getPostUrl } from '../../util/Posts';

import './BlogPostCard.style';

/** @namespace Satisfly/MagefanBlog/Component/BlogPostCard/Component */
export class BlogPostCardComponent extends PureComponent {
    static propTypes = {
        post: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        isPlaceholder: PropTypes.bool,
        isRelated: PropTypes.bool
    };

    static defaultProps = {
        post: false,
        isPlaceholder: false,
        isRelated: false
    };

    renderImage() {
        const {
            post: { first_image, title },
            post
        } = this.props;

        if (!first_image) {
            return <div mix={ { block: 'BlogPostCard', elem: 'Image' } } />;
        }

        return (
            <Link
              to={ getPostUrl(post) }
              title={ __('View %s', title) }
              mix={ { block: 'BlogPostCard', elem: 'ImageWrapper' } }
            >
                <Image mix={ { block: 'BlogPostCard', elem: 'Image' } } ratio="square" src={ first_image } />
            </Link>
        );
    }

    renderTitle() {
        const {
            post: { title },
            post,
            isRelated
        } = this.props;

        if (!title) {
            return null;
        }

        return (
            <Link to={ getPostUrl(post) }>
                <div block="BlogPostCard" elem="Title" mods={ { isRelated } }>
                    { title }
                </div>
            </Link>
        );
    }

    renderDate() {
        const {
            post: { publish_time }
        } = this.props;

        if (!publish_time) {
            return null;
        }

        return (
            <div mix={ { block: 'BlogPostCard', elem: 'Date' } }>
                <TextPlaceholder
                  mix={ { block: 'BlogPostCard', elem: 'Date' } }
                  content={ convertDateFormat(publish_time) }
                />
            </div>
        );
    }

    renderContents() {
        const { isPlaceholder, isRelated } = this.props;

        if (isPlaceholder) {
            return null;
        }

        return (
            <div block="BlogPostCard" elem="ContentWrapper" mods={ { expand: true, isRelated } }>
                { this.renderImage() }
                <div block="BlogPostCard" elem="Details" mods={ { isRelated } }>
                    <div block="BlogPostCard" elem="DateWrapper">
                        { this.renderDate() }
                    </div>
                    { this.renderTitle() }
                </div>
            </div>
        );
    }

    render() {
        const { isPlaceholder } = this.props;
        return (
            <div block="BlogPostCard" mods={ { isLoading: isPlaceholder } }>
                { this.renderContents() }
            </div>
        );
    }
}

export default BlogPostCardComponent;
