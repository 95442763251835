/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import {
    VALIDATION_INPUT_TYPE_INPUT as SOURCE_VALIDATION_INPUT_TYPE_INPUT,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_INPUT_TYPE_TEXT,
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES
} from 'SourceUtil/Validator/Config';
import BrowserDatabase from 'Util/BrowserDatabase';

export * from 'SourceUtil/Validator/Config';

const { storeConfig: { code = 'default' } = {} } = BrowserDatabase.getItem('config') || {};

export const plStore = code === 'default';
export const plRegexp = 'a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\\-\\s';

export const VALIDATION_INPUT_TYPE_INPUT = {
    ...SOURCE_VALIDATION_INPUT_TYPE_INPUT,
    postcode: 'postcode',
    vat: 'vat'
};

export const VALIDATION_INPUT_TYPE = {
    ...VALIDATION_INPUT_TYPE_TEXT,
    ...VALIDATION_INPUT_TYPE_NUMBER,
    ...VALIDATION_INPUT_TYPE_INPUT
};

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [VALIDATION_INPUT_TYPE.postcode]: __('Incorrect postcode!'),
    [VALIDATION_INPUT_TYPE.vat]: __('Incorrect vat number!'),
    isRequired: __('Complete the data')
};

export const VALIDATION_RULES = {
    // Text
    [VALIDATION_INPUT_TYPE.alpha]: plStore ? new RegExp(`^[${plRegexp}]+$`, 'i') : /^[a-z]+$/i,
    [VALIDATION_INPUT_TYPE.alphaSpace]: plStore ? new RegExp(`^[${plRegexp}]+$`, 'i') : /^[a-z\s]+$/i,
    [VALIDATION_INPUT_TYPE.alphaNumeric]: /^[a-z0-9]+$/i,
    [VALIDATION_INPUT_TYPE.alphaDash]: /^[a-z0-9_\\-]+$/i,
    [VALIDATION_INPUT_TYPE.url]: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[\d]+)?(\/|\/([\w#!:.?+=&%@\-\\/]))?$/,

    // Numbers
    [VALIDATION_INPUT_TYPE.numeric]: /^[\d]+$/,
    [VALIDATION_INPUT_TYPE.numericDash]: /^[\d\-\s]+$/,
    [VALIDATION_INPUT_TYPE.integer]: /^\\-?[\d]+$/,
    [VALIDATION_INPUT_TYPE.decimal]: /^\d*\.?\d*$/,
    [VALIDATION_INPUT_TYPE.natural]: /^[\d]+$/i,
    [VALIDATION_INPUT_TYPE.naturalNoZero]: /^[1-9][\d]*$/i,

    // Inputs
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.email]: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/,
    [VALIDATION_INPUT_TYPE.date]: /\d{4}-\d{1,2}-\d{1,2}/,
    [VALIDATION_INPUT_TYPE.postcode]: /^\d{2}-\d{3}$/,
    [VALIDATION_INPUT_TYPE.phone]: /^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{3})$/,
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.emailList]: /^(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+([,\s]+(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
    [VALIDATION_INPUT_TYPE.vat]: /[\d]{10}/
};
