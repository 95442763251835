/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { connect } from 'react-redux';

import DataContainer from 'SourceUtil/Request/DataContainer';

import { GDPRContext } from '../../context/GDPR.context';
import CookiesDetailsModalComponent from './CookiesDetailsModal.component';

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesDetailsModal/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesDetailsModal/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesDetailsModal/Container */
export class CookiesDetailsModalContainer extends DataContainer {
    /** defines the props for this container */
    static propTypes = {};

    static contextType = GDPRContext;

    /**
     * returns the props that should be passed to the container.
     */
    containerProps = () => {
        const {
            selectedCookiesDetails,
            dismissCookiesDetailsModal,
            isCookiesDetailsModalVisible
        } = this.context;

        return {
            groupData: selectedCookiesDetails,
            dismissPopup: dismissCookiesDetailsModal,
            isCookiesDetailsModalVisible
        };
    };

    /**
     * returns the functions that should be passed to the component,
     * after binding it to this container.
     *
     * we should always put make it, even if it was empty, to make it easier for other developers to
     * make a plugin for this container/component.
     */
    containerFunctions= {};

    render() {
        return (
            <CookiesDetailsModalComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiesDetailsModalContainer);
