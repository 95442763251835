/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const cookiesType = PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    lifetime: PropTypes.string,
    provider: PropTypes.string,
    type: PropTypes.string
});

export const cookiesGroupDataType = PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    groupId: PropTypes.string,
    isEssential: PropTypes.bool,
    cookies: PropTypes.arrayOf(cookiesType)
});

export const CheckboxItemDataType = PropTypes.shape({
    name: PropTypes.string,
    consent_text: PropTypes.string,
    is_enabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    link: PropTypes.string
});

export const CheckboxGroupDataType = PropTypes.arrayOf(
    CheckboxItemDataType
);
