/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getCheckoutEventData, getCheckoutOptionEventData } from '../data/checkout';
import { getCustomerData } from '../data/customer';
import { getPurchaseEventData } from '../data/purchase';
import {
    GTM_EVENT_KEY_CHECKOUT,
    GTM_EVENT_KEY_CHECKOUT_OPTION,
    GTM_EVENT_KEY_PURCHASE
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

export const fireCheckoutOptionEvent = debounceCallback(async (step, option) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT_OPTION,
        customerId,
        ...await getCheckoutOptionEventData(step, option)
    });
});

export const fireCheckoutEvent = debounceCallback(async (step, option) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT,
        customerId,
        ...await getCheckoutEventData(step, option)
    });
});

/** @namespace Satisfly/Gtm/Event/Checkout/firePurchaseEvent */
export const firePurchaseEvent = async (orderId, guestQuoteId) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_PURCHASE,
        customerId,
        ...await getPurchaseEventData(orderId, guestQuoteId)
    });
};
